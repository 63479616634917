import request from "../utils/request";


export function getCompanies() {
    return request({
        url: "/client/getcompanies",
        method: "GET",
        timeout: 10000,
    })
}

export function addClient(data) {
    return request({
        url: "/client/new",
        method: "POST",
        timeout: 10000,
        data
    })
}

export function companyClientList(params) {
    return request({
        url: "/client/list",
        method: "GET",
        timeout: 10000,
        params: params
    })
}

export function getPendingClientRequests(params) {
    return request({
        url: "/client/pending/requests",
        method: "GET",
        timeout: 10000,
        params: params
    })
}

export function changeCompanyClientStatus(data) {
    return request({
        url: "/client/update/status",
        method: "PUT",
        timeout: 10000,
        data
    })
}

export function contributorsList(params) {
    return request({
        url: "/contributors/list",
        method: "GET",
        timeout: 10000,
        params: params
    })
}

export function companyClientListActivo(params) {
    return request({
        url: "/client/activo/list",
        method: "GET",
        timeout: 10000,
        params: params
    })
}

export function VacantesContributors(query = {}) {
    return request({
        url: "/contributors/list/positions",
        method: "get",
        timeout: 60000,
        params: query,
    });
}

export function VacantesOfClient(query = {}) {
    return request({
        url: "/client/list/vacantes",
        method: "GET",
        timeout: 100000,
        params: query,
    });
}

export function CandidateContratado(query = {}) {
    return request({
        url: "/client/canditate/contratado",
        method: "GET",
        timeout: 100000,
        params: query,
    });
}
export function CandidateContratadoClients(query = {}) {
    return request({
        url: "/client/vacante/canditate/contratado",
        method: "GET",
        timeout: 100000,
        params: query,
    });
}
export function UploadConveniosCompany(data) {
    return request({
        url: "/client/upload/convenios",
        method: "PUT",
        data,
    });
}

export function UpdateDataAdminstration(data) {
    return request({
        url: "/client/update/data/administration",
        method: "PUT",
        data,
    });
}

export function exportClientExcel(query = {}) {
    return request({
      url: "/client/vacantes/contratados/exportExcel",
      method: "get",
      timeout: 60000,
      params: query,
    });
  }

