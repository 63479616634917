<template>
    <div class="no-modal">
        <div class="container-busqueda">
            <div class="cuadro-busqueda col-md-4">
                <form class="input-group" @keyup="getCompany()">
                    <input type="search" class="form-control" v-model="search"
                        placeholder="Buscar por título de vacante..." />
                </form>
            </div>
        </div>
        <div class="container-table">
            <table class="table align-middle table-responsive table-hover caption-top">
                <thead>
                    <tr align="center" style="font-family: Poppins;">
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Postulantes</th>
                        <th scope="col">Aceptados / Contratados</th>
                        <th scope="col">Gestión de Postulantes</th>
                    </tr>
                </thead>
                <tbody v-if="response.loading">
                    <tr>
                        <td colspan="7">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-danger" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else v-for="(item, i) in listVacantes" :key="i + 1" v-show="filter(item)">
                    <tr align="center" style="font-family: Poppins;">
                        <td>{{ i + 1 }}</td>
                        <td>{{ item.titulo }}</td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.postulates || 0 }}</td>
                        <td>
                            <div class="btn-margin-main">
                                <button class="btn-style" title="Ver Contratados" v-show="(item.postulates > 0)"
                                    v-on:click="linkAdministration(item._id, item.type)">
                                        <box-icon name='check-shield' color="#03337A"></box-icon>
                                </button>
                            </div>
                        </td>
                        <td>
                            <div class="btn-margin-main">
                                <button class="btn-style" title="Ver Postulantes" v-show="(item.postulates > 0)"
                                    v-on:click="linkVacante(item._id)">
                                    <box-icon name='group' color="#03337A"></box-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="js">
import * as ClientService from "../../services/client"
import moment from "moment";
moment.locale("es");

export default {
    data: () => ({
        moment: moment,
        search: "",
        searchInput: "",
        vigencia: new Date(moment(new Date()).subtract('3', 'months').format('YYYY-MM-DD hh:mm:ss')),
        listVacantes: [],
        response: {
            loading: false
        }
    }),
    mounted() {
        const id_from_url = this.$route.params.id;
        this.getCompany(id_from_url);
    },
    methods: {
        async getCompany(id) {
            try {
                this.response.loading = true
                let { data } = await ClientService.VacantesOfClient({ id: id, search: this.search });
                this.listVacantes = data.data;

                this.response.loading = false
            } catch (error) {
                this.response.loading = false
            }
        },
        //TODO: Metodo Ordenar Vacantes fecha
        filterVacantesTFecha(vacantes) {
            vacantes.sort((a, b) => {
                if (a.createdAt == b.createdAt) {
                    return 0;
                }
                if (a.createdAt > b.createdAt) {
                    return -1;
                }
                return 1;
            });
            return vacantes;
        },
        //TODO: Filtrado O Busqueda Por Vacantes
        filter(vacante) {
            if (this.searchInput != "") {
                return vacante.titulo.toLowerCase().match(this.searchInput.toLocaleLowerCase()) || vacante.ubicacion.toLowerCase().match(this.searchInput.toLocaleLowerCase()) || vacante.modalidad.toLowerCase().match(this.searchInput.toLocaleLowerCase());
            }
            else {
                return vacante;
            }
        },
        linkAdministration(id, type) {
            type === 'Becario' ? this.$router.push(`/administration/clients/vacante/${id}/becario`) : this.$router.push(`/administration/clients/vacante/${id}/egresado`)
        },
        linkVacante(id) {
            this.$router.push(`/vacante/${id}/postulantes`);
        },
    },
};
</script>

<style land="scss" scoped>
@import "../../styles/talento.scss";
</style>